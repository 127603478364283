function showBody() {
  document.body.style.opacity = 1;
}

function loadMbox(mbox) {
  const { target } = window.adobe;

  const onRender = (event) => {
    console.log('AT event: ', event); // eslint-disable-line
    showBody();
  };

  document.addEventListener(target.event.CONTENT_RENDERING_NO_OFFERS, onRender);
  document.addEventListener(target.event.CONTENT_RENDERING_SUCCEEDED, onRender);
  document.addEventListener(target.event.CONTENT_RENDERING_FAILED, onRender);

  target.getOffer({
    mbox,
    params: {},
    success: (offer) => {
      target.applyOffer({ mbox, offer });
    },
    error: showBody,
  });
}

window.addEventListener('DOMContentLoaded', () => {
  setTimeout(showBody, 1500);
});

document.addEventListener('at-library-loaded', () => {
  loadMbox('target-global-mbox');
});
